import { Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SotialIcon from "../components/SotialIcon";
import Logo from './../assets/Logo/LogoM.png'


function Footer() {
    const { t } = useTranslation()
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                p: 0,
                pt: 1,
                gap: 1,
                borderTopLeftRadius: '40px',
                borderTopRightRadius: '40px',

            }}
            style={{ background: 'linear-gradient(180deg, rgba(58,134,96,1) 35%, rgba(26, 103, 64, 1) 100%)' }}
        >
            <img src={Logo} height={90} alt="logo" style={{
                padding: "15px",
                background: "#fff",
                borderRadius: "20px"
            }} />
            <Stack direction={{ xs: "column", sm: "row" }} flexWrap={'wrap'} gap={2}>
                <Link
                    to="/"
                    style={{
                        color: "#FFF",
                        textDecoration: "none",
                        fontSize: 15,

                    }}
                >
                    <Typography fontWeight={"bold"}>{t('linksPages.home')}</Typography>
                </Link>
                <Link
                    to="/about"
                    style={{
                        color: "#FFF",
                        textDecoration: "none",
                        fontSize: 15,

                    }}
                >
                    <Typography fontWeight={"bold"}>{t('linksPages.aboutUs')}</Typography>
                </Link>
                <Link
                    to="/contact"
                    style={{
                        color: "#FFF",
                        textDecoration: "none",
                        fontSize: 15,
                        fontWeight: "bold",
                    }}
                >

                    <Typography fontWeight={"bold"}>{t('linksPages.contact')}</Typography>
                </Link>
                <Link
                    to="/service"
                    style={{
                        color: "#FFF",
                        textDecoration: "none",
                        fontSize: 15,
                        fontWeight: "bold",
                    }}
                >
                    <Typography fontWeight={"bold"}>{t('linksPages.PhotoGallery')}</Typography>
                </Link>

            </Stack>

            <SotialIcon />
            <Typography fontWeight={"bold"} sx={{ color: '#FFF' }} >{t('copeRight')}</Typography>
            <Typography fontWeight={"bold"}> <Link to={'https://revampbrands.com/'} style={{ textDecoration: "none", color: "#FFF" }} >{t('div')}</Link> </Typography>
        </Box>
    );
}

export default Footer;
