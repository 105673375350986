import NavBar from "./components/NavBar";
import { Routes, Route, useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Home from "./pages/Home";
import About from "./pages/About";
import Footer from "./components/Footer";
import Contact from "./pages/Contact";
import AlpomServ from "./pages/AlpomServ";
import Service from "./pages/Service";
import { useEffect } from 'react';

function App() {
    const ScrollToTop = () => {
        const { pathname } = useLocation();

        useEffect(() => {
            window.scrollTo(0, 0);
        }, [pathname]);

        return null;
    };
    return (
        <div className="App" dir="rtl">
            <CssBaseline />
            <NavBar />
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/service" element={<AlpomServ />} />
                <Route path="/service/:id" element={<Service />} />
            </Routes>
            <Footer />
        </div>
    );
}

export default App;
