import { Avatar, Box, Typography } from '@mui/material'
import React from 'react'
import SettingsPhoneIcon from '@mui/icons-material/SettingsPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import RoomIcon from '@mui/icons-material/Room';
const ContactData = () => {
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#01542a', p: { md: 3, xs: 1 }, color: '#FFF', mt: '25%', alignItems: 'flex-start', justifyContent: 'space-around', position: 'relative', borderRadius: '30px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexDirection: 'column' }} >
                    <Typography variant='h4' > تواصل معنا عبر </Typography>
                    <Typography variant='h5' >بيانات التواصل معنا</Typography>
                </Box>
                <Box display={'flex'} flexDirection={'column'} gap={2} >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} >
                        <SettingsPhoneIcon sx={{ fontSize: '1.7rem' }} />
                        <Typography sx={{ fontSize: '1.2rem' }} >01006861026</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} >
                        <EmailIcon sx={{ fontSize: '1.7rem' }} />
                        <Typography sx={{ fontSize: '1.2rem' }} >info@mafazacompany.com</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} >
                        <RoomIcon sx={{ fontSize: '1.7rem' }} />
                        <Typography sx={{ fontSize: '1.2rem' }} >المنوفية - مركز السادات - المنطقة السادسة الصناعية</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} >
                    <Avatar sx={{ height: '100px', width: '100px', background: '#8d959a', color: '#8d959a' }} />
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'space-around', position: 'absolute', top: '85%', left: '10%' }}>
                        <WhatsAppIcon sx={{ color: '#FFF', fontSize: '2.5rem' }} />
                        <InstagramIcon sx={{ color: '#FFF', fontSize: '2.5rem' }} />
                        <FacebookIcon sx={{ color: '#FFF', fontSize: '2.5rem' }} />
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default ContactData