import i18next from 'i18next';
import {initReactI18next} from 'react-i18next'
import languageDetector from "i18next-browser-languagedetector";
import BackEnd from "i18next-http-backend"

i18next
    .use(initReactI18next)
    .use(languageDetector)
    .use(BackEnd)
    .init({
        debug: true,
        fallbackLng: 'ar',
        lng: 'ar',
        saveMissing:true,
        default: 'ar'
    })