
import URFAZAPlus from './../assets/Service/M3.jpg'
import PHOSPHOFAZA from './../assets/Service/M14.jpg'
import n1 from './../assets/Service/1.jpg'
import n2 from './../assets/Service/2.jpg'
import n3 from './../assets/Service/3.jpg'
import n4 from './../assets/Service/4.jpg'
import n5 from './../assets/Service/5.jpg'
import n6 from './../assets/Service/6.jpg'
import n10 from './../assets/Service/10.jpg'
import n11 from './../assets/Service/11.jpg'
import n12 from './../assets/Service/12.jpg'
import n13 from './../assets/Service/13.jpg'
import n14 from './../assets/Service/14.jpg'
import n15 from './../assets/Service/15.jpg'
import n16 from './../assets/Service/16.jpg'
import n17 from './../assets/Service/17.jpg'
import n18 from './../assets/Service/18.jpg'
import n19 from './../assets/Service/19.jpg'
import n20 from './../assets/Service/20.jpg'
import n21 from './../assets/Service/21.jpg'
import n22 from './../assets/Service/22.jpg'
import n23 from './../assets/Service/23.jpg'
import n24 from './../assets/Service/24.jpg'
import n25 from './../assets/Service/25.jpg'
import n26 from './../assets/Service/26.jpg'

export const products = [
        // /////////////////////////////////////////New
        {
                id: 18,
                imgURL: n1,
                descEn: `High potassium
<br/>"6-6-43" A smart investment for farmers.
<br/>✅Chemical composition:-,
<br/>✔️N 6%,
<br/>✔️P.o. 6%,
<br/>✔️K.o. 43%,
<br/>✅Compound fertilizer that is completely soluble in water.,
<br/>✅Factory from the finest European materials.,
<br/>✅It contains a high percentage of potassium, which benefits the plant.,
<br/>✅ Increases the speed of growth and ripening of fruits.,
<br/>✅Helps overcome plant stress conditions.,
<br/>✅It has a strong role in improving the quality of roots.,
<br/>✅Used with irrigation water.,
<br/>✅It regulates the plant’s use of water and improves the characteristics of the fruits.,
<br/>✅ Increases the sugar content of fruits.`,
                descAr: `عالي بوتاسيوم 
"6-6-43" استثمار ذكي للمزارعين.
<br/>✅التركيب الكيميائي:- 
<br/>✔️N 6%
<br/>✔️P.o 6%
<br/>✔️K.o 43% 
<br/>✅سماد مركب تام الذوبان في الماء.
<br/>✅مصنع من اجود الخامات الاوروبيه.
<br/>✅ييحتوي علي نسبة عالية من البوتاسيوم بما يفيد النبات.
<br/>✅يزيد من سرعة نمو ونضج الثمار.
<br/>✅يساعد  علي التغلب علي ظروف اجهاد النبات. 
<br/>✅له دور قوي في تحسين نوعية الجذور.
<br/>✅يستخدم مع ماء الري.
<br/>✅ينظم استفادة النبات من الماء <br/>ويحسن من مواصفات الثمار.
✅يزيد من محتوي الثمار<br/> للسكريات.`
        },
        {
                id: 19,
                imgURL: n2,
                descEn: `Balanced 19-19-19.
<br/>Chemical composition:-
<br/>Potassium 19%
<br/>Phosphorus.19%
<br/>Nitrogen. 19%
<br/>☑️ A balanced compound fertilizer that is completely soluble in water.
<br/>☑️ Fertilizer made from highly resistant and quality materials.
<br/>☑️ It has an acidic effect that increases absorption.
<br/>☑️ Helps adjust soil ph.
<br/>☑️ Provides the plant with the necessary fertilizer elements.
<br/>☑️One of the best fertilizer compounds and contains macro and micro elements in a balanced manner.
<br/>☑️It can be used in irrigation water and leads to increased production.
<br/>☑️Used with drip irrigation systems.
<br/>☑️ Reduces the use of monofertilizers.
<br/>☑️Increase the rates of utilization of the fertilizer unit.
<br/>☑️Reducing ground water pollution.
<br/>☑️ Reducing the cost of transportation, storage and use.`,
                descAr: `متوازن 19-19-19.
<br/>التركيب الكيميائي:- 
<br/>بوتاسيوم 19%
<br/>فوسفور.19%
<br/>نيتروجين.  19%
<br/>☑️سماد مركب متوازن تام الذوبان في الماء.
<br/>☑️سماد مصنع من خامات عالية المقاومة والجودة.
<br/>☑️له تأثير حامضي يزيد من الامتصاص.
<br/>☑️يساعد علي ضبط ph التربة.
<br/>☑️يمد النبات بالعناصر السمادية الضرورية.
<br/>☑️من أفضل المركبات السمادية ويحتوي علي عناصر كبري وصغري بصورة متوازنة.
<br/>☑️يمكن استخدامه في ماء الري ويؤدي إلي زيادة الإنتاج.
<br/>☑️يستخدم مع أنطمة الري بالتنقيط.
<br/>☑️يقلل من استخدام الأسمدة الأحادية.
<br/>☑️زيادة معدلات الاستفادة من وحدة السماد.
<br/>☑️تقليل تلوث المياه الأرضية.
<br/>☑️خفض تكلفة النقل والتخزين والاستخدام.`
        },
        {
                id: 21,
                imgURL: n3,
                descEn: `High phosphorus 10-40-10.
<br/>🔅Chemical composition:-
<br/>Nitrogen 10%
<br/>Phosphorous 40%
<br/>Potassium 10%
<br/>💠A compound fertilizer that is completely soluble in water, used with all modern and traditional irrigation systems.
<br/>💠Made of high quality and purity materials, free of harmful heavy elements.
<br/>💠Fertilizer containing a high percentage of phosphorus, which helps the plant build nucleic acids and energy compounds.
<br/>💠It helps with the vitality of the plant at all different stages of its growth.
<br/>💠It stimulates the plant to increase the activity of the root system and the growth of root hairs.
<br/>💠It increases and speeds up the ripening of fruits in the plant.
<br/>💠It plays an important role in absorbing water and nutrients from the soil and transporting them to the leaves.
<br/>💠The plant helps control the water balance by regulating the opening and closing of stomata.
<br/>💠It has a role in the formation of carbohydrates at the fruit formation stage.
<br/>💠The plant helps in increasing cell divisions and thus the elongation of the plant. It eliminates the phenomenon of stunting in new plant growth.`,
                descAr: `عالي الفوسفور 10-40-10.
<br/>🔅التركيب الكيميائي:- 
<br/>نيتروجين ١٠٪ 
<br/>فوسفور ٤٠٪ 
<br/>بوتاسيوم ١٠٪
<br/>💠سماد مركب تام الذوبان في الماءيستخدم مع كل أنظمة الري الحديثة والتقليدية.
<br/>💠مصنع من خامات عالية الجودة والنقاوة خالية من العناصر الثقيلة الضارة.
<br/>💠سماد يحتوي علي نسبة عالية من الفوسفور الذي يساعد النبات علي بناء الأحماض النووية ومركبات الطاقة.
<br/>💠يساعد من حيوية النبات في كل مراحل نموه المختلفة.
<br/>💠يحفز النبات علي زيادة نشاط المجموع الجذري ونمو الشعيرات الجذرية.
<br/>💠يعمل علي زيادة وسرعة نضج الثمار عند النبات.
<br/>💠يلعب دورا هاما في امتصاص الماء والعناصر الغذائية من التربة وانتقالها في الأوراق.
<br/>💠يساعد النبات علي التحكم في التوازن المائي من خلال تنظيم فتح وغلق الثغور.
<br/>💠له دور في تكوين الكربوهيدرات عند مرحلة تكوين الثمار.
<br/>💠يساعد النبات في زيادة انقسامات الخلايا وبالتالي استطالة النبات فهو يقضي علي ظاهرة التقزم في النموات  الحديثة في النبات.`
        },
        {
                id: 37,
                imgURL: n19,
                descEn: `✔️We offer Calciofaza product
<br/>🔘Chemical composition:-
<br/>✔️Calcium oxide 34%
<br/>✔️Free calcium 25%
<br/>✔️Nitrogen 10%
<br/>Features and characteristics:-
<br/>☑️ A liquid compound containing calcium and nitrogen
<br/>☑️ Helps with salinity continuously.
<br/>☑️Pure source of calcium.
<br/>☑️ Helps form plant cell walls.
<br/>☑️ It helps the plant produce better quality fruits, tubers, or grains.
<br/>☑️Helps regularize and coordinate the size of the fruits.
<br/>☑️ It works to elongate cells and encapsulate cell walls.
<br/>☑️ It plays a major role in improving the pollination process and fruit setting.
<br/>☑️ Improves fruit characteristics and protects them from infection.`,
                descAr: `✔️بنقدم منتج كالسيوفازا 
<br/>🔘التركيب الكيميائي:- 
<br/>✔️أكسيد كالسيوم ٣٤٪ 
<br/>✔️كالسيوم حر ٢٥٪
<br/>✔️نيتروجين ١٠٪
<br/>المميزات والخصائص:-
<br/>☑️مركب سائل يحتوي علي كالسيوم ونيتروجين
<br/>☑️يساعد على الملوحة بشكل مستمر.
<br/>☑️مصدر نقي للكالسيوم.
<br/>☑️يساعد علي تكوين جدران الخلايا النباتية.
<br/>☑️يساعد النبات علي إنتاج نوعية أفضل من الفاكهة أو الدرانات أو الحبوب.
<br/>☑️يساعد علي انتظام وتناسق حجم الثمار.
<br/>☑️يعمل علي استطالة الخلايا وتغليف جدر الخلايا.
<br/>☑️له دور كبير من تحسين عميلة التلقيح وعقد الثمار.
<br/>☑️يحسن مواصفات الثمار ويحميها من الإصابة.`
        },

        {
                id: 17,
                imgURL: PHOSPHOFAZA,
                name: "PHOSPHOFAZA",
                subtextEn: `chemical chemicals
Phosphor P2O,
42%
romi h
Density: 1.5 g/cm3
`,
                subtextAr: `التركيب الكيميائـي
فسفور ,P
الكثافة
1,0:
جم اسم
%. Er
`,
                descEn: `Features & Charactries
- An essential source of phosphorus necessary for plant growth.
- A liquid fertilizer that is easily soluble in water containing a high percentage of phosphorus.
- Highly effective in alkaline and calcareous soils. - It has an excellent effect on adjusting the pH of alkaline soil.
-Treats the symptoms of phosphorus deficiency on plants.
- It has a fundamental role in the formation of chloro- phyll and nucleic acids inside plant cells.
- Very effective in fixing flowers, increasing the percentage of nodes and early ripening of the crop. - A very good tonic for roots and root hairs during different stages of growth.
-It is also used for cleaning and cleaning sprinklers and drip hoses.
`,
                descAr: `مصدر أساسي الفسفور اللازم لنمو النبات.
سماد سائل سهل الذوبان في الماء يحتوي علي نسبه عاليه من الفسفور.
ذو فاعليه عاليه في الأراضي القلويه والجيريه.
له تأثير ممتاز علي ضبط PH التربه القلويه.
يعالج اعراض نقص الفسفور علي النبات.
له دور أساسي في تكوين الكلوروفيل والاحماض النوويه داخل الخلايا النباتيه.
فعال جدا في تثبيت الازهار وزياده نسبه العقد والنضج المبكر المحصول.
منشط جيد جدا للبذور والشعيرات الجذره خلال مراحل النمو المختلفه.
يستخدم ذلك لغرض تنظيف وتسليك الرشاشات وخراطيم التنقيط.`
        },
        {
                id: 15,
                imgURL: URFAZAPlus,
                name: `URFAZA Plus`,
                subtextEn: `Fertilizer in powder form contains nitrogen, potassium and magnesium in a form that is easily soluble in water and
quickly absorbed in the plant
Composition:
Nitrogen 40%
Potassium 5%
Magnesium 0.5%
`,
                subtextAr: `التعريف :
سماد في صورة بودر يحتوى على نيتروجين و بوتاسيوم على نيتروجيـ وماغنسيوم في صورة سهلة الذوبان في الماء وسريعة الامتصاص في النبات
التركيب :
نيتروجين %40 N
بوتاسيوم %5 K
ماغنسيوم %0.5 Mg
`,
                descEn: `properties:
-Pure source of nitrogen (nitrogen)
-Treats nitrogen deficiency on the plant
-It is used in fertilizing agricultural soil and the compound solution is used directly in plant nutrition by spraying the vegetative aggregate
- Helps to facilitate the microelements in the soil as well as facilitate the elements of calcium, sulfur and phosphorus in the soil
- Nitrogen is one of the major elements that form the plant tissues, which depend on it for their growth
- Enhances the absorption of other nutrients found in the soil - Nitrogen has a major role in the formation of protein inside the plant, where it turns into amino acids and translates into proteins inside the plant
- Contributes significantly to the photosynthesis process, which promotes the growth of green parts of the plant and the increase of branches
-Improves soil specifications by supplying it with the necessary nitrogen and activating the work of soil organisms -Provides the plant with an appropriate proportion of potassium and magnesium, which promotes plant growth, fruit scaling and improving the color of the plant by activat-
ing the formation of chlorophyll through the element magne- 10
sium
`,
                descAr: `خصائص المركب وأهميته :
- مصدر نقى للأزوت (النيتروجين )
- يعالج نقص النيتروجين على النبات
- يستخدم فى تسميد التربة الزراعية ومحلول المركب يستخدم مباشرة في تغذية النبات عن طريق رش المجموع الخضري - يساعد على تيسير العناصر الصغرى بالتربه وكذلك تيسير عناصر الكالسيوم والكبريت والفوسفور بالتربة
- الازوت عنصر من العناصر الكبرى التى تتشكل منها انسجة النبات والتي تعتمد عليها في نموها
- يعزز من امتصاص العناصر المغذية الاخرى الموجودة في التربة - النيتروجين له دور رئيسى فى تكوين البروتين داخل النبات حيث يتحول إلى احماض أمينية ويترجم الى بروتينات داخل النبات - يساهم بشكل كبير فى عملية البناء الضوئى مما يعزز نمو الأجزاء الخضراء في النبات وزيادة الأفرع
- يحسن من مواصفات التربة عن طريق امدادها بالنيتروجين اللازم وتنشيط عمل الكائنات الحية بالتربة
- يمد النبات بنسبة مناسبة من البوتاسيوم والماغنسيوم مما يعزز نمو النبات وتحجيم الثمار وتحسين لون النبات عن طريق تنشيط تكوين الكلورفيل من خلال عنصر الماغنسيوم
`
        },
        {
                id: 22,
                imgURL: n4,
                descEn: `Mafaza Company helps you improve your product
Dosage and frequency of use
Foliar spraying: 2 - 3 grams / liter of water
Ground fertilization: 2-3 kg/acre, depending on the type and age of the crop
Benefits of the compound
Manganese is involved in the process of photosynthesis as a coenzyme group of enzymes
It stimulates the splitting of the water molecule, which is of course the first step in the photosynthesis process.
These reactions cannot take place without manganese
Coenzymes for chloroplast formation enzymes, coenzymes for oxidation processes
Reduction within the plant, the most important of which is the reduction of nitrate to nitrite, that is, it has a role in the journey of converting nitrate into protein
. Therefore, it is important in preventing the accumulation of nitrates within the plant. It plays an important role in the efficiency of the photosynthesis process within the plant in the light and dark phases
Therefore, it affects the products of photosynthesis from glucose, which are then transformed
For carbohydrates, proteins and some elements that the plant needs, this is very important for crops
Which need to store food compounds
Manganese is considered the key to the success of tuber, root and leguminous crops, as it is very important for sizing
The fruits of these crops. It can replace magnesium in many biological reactions
Dosage and frequency of use`,
                descAr: `شركه مفازا بتساعدك علي تحسين منتجك 
الجرعة ومعدل الاستخدام
الرش الورقي : 2 - 3 جرام / لتر ماء
التسميد الأرضى : 2 - 3 كجم / فدان حسب نوع وعمر المحصول
فوائد المركب
يدخل المنجنيز في عملية البناء الضوئي وذلك كمرافق أنزيمي المجموعة الأنزيمات التي
تحفز تقسيم جزيء الماء وهي بالطبع الخطوة الأولى في عملية التركيب الضوئي.
وهذه التفاعلات لا تتم بدون المنجنيز
مرافق الزيمي لأنزيمات تكوين البلاستيدات الخضراء، مرافق انزيمي لعمليات الأكسدة
والاختزال داخل النبات وأهمها اختزال النترات إلى نتريت، أي له دور في رحلة تحول النترات الى بروتين
. وبالتالي هو مهم في منع تراكم النترات داخل النبات دور مهم في كفاءة عملية التركيب الضوئي داخل النبات في مرحلة الضوء والظلام
وبالتالي فهو يأثر على نواتج البناء الضوئي من سكر الكلوكوز التي يتحول بعدها
للكربوهيدرات وبروتينات وبعض العناصر التي يحتاجها النبات، وهذا مهم جدا للمحاصيل
التي تحتاج لتخزين المركبات الغذائية
، ويعتبر المنجنيز مفتاح نجاح المحاصيل الدرنية والجذرية والبقولية فهو مهم جدا لتحجيم
ثمار هذه المحاصيل. ويمكن أن يحل محل المغنيزيوم في المديد من التفاعلات الحيوية
الجرعة ومعدل الاستخدام`
        },
        {
                id: 23,
                imgURL: n5,
                descEn: `Mafaza Company for Agricultural Investments and Development
Dosage and frequency of use
Foliar spraying: 2 - 3 grams / liter of water
Ground fertilization: 2-3 kg/acre, depending on the type and age of the crop
Benefits of the compound
Quick treatment Symptoms of zinc deficiency, which is involved in energy production. It helps the plant complete all vital processes. Zinc is an important and necessary element for elongating cells and brightening flowers. It plays a role in the plant’s ability to retain moisture and form protein. It is involved in the formation of growth hormones and participates in the formation and production of pills
The pollen and its increase at flowering and fruits are completely soluble in water to increase the yield and improve the quality of the fruits. It is important for treating yellowing resulting from a deficiency of zinc. It is involved in the synthesis of many enzymes needed in building protein and also many of the enzymes needed for oxidation and reduction responsible for the respiratory process.`,
                descAr: `شركه مفازا للاستثمارات والتنمية الزراعية
الجرعة ومعدل الاستخدام
الرش الورق : 2 - 3 جرام / لتر ماء
التسميد الارضي : 2 - 3 كجم / فدان حسب نوع وعمر المحصول
فوائد المركب
علاج سريع الأعراض نقص عنصر الزنك الذي يدخل في انتاج الطاقة. يساعد النبات علي اتمام كل العمليات الحيوية يعتبر الزنك عنصر هام وضروري لاسطالة الخلايا وتفتيح الازهار يلعب دور في قدره النبات على الاحتفاظ بالرطوبة وتكوين البروتين . يدخل في تكوين هرمونات النمو ويشارك في تكوين وانتاج حبوب
اللقاح وزياده عند الازهار والثمار تام الذوبان في الماء علي زيادة المحصول وتحسين جودة الثمار هام لعلاج الاصفرار الناتج عن نقص عنصر الزنك يدخل في تركيب العديد من الانزيمات الازمة في بناء البروتين وايضا العديد من الانزيمات اللازمة للأكسدة والاختزال المسؤولة عن عمليه التنفس`
        },
        {
                id: 24,
                imgURL: n6,
                descEn: `Dosage and frequency of use
Foliar spraying: 2 - 3 grams / liter of water
Ground fertilization - 2-3 kg/acre depending on the type and age of the crop
Benefits of the compound
Quick treatment Symptoms Iron deficiency, which is involved in energy production
It helps the plant to complete all vital processes. Iron is an important and necessary element for the formation of tryptophan, which is the amino acid that helps the plant resist stress resulting from salinity and temperature changes. It plays a role in the plant’s ability to retain moisture and form protein. It is involved in the formation of growth hormones and participates in the formation and production of grains. The pollen and the increase in the number of flowers and fruits are completely soluble in water and help increase the yield and improve the quality of the fruits
Important treatment for yellowing caused by iron deficiency
Many enzymes needed in protein synthesis and also many other enzymes
Enzymes necessary for oxidation and reduction responsible for the respiration process`,
                descAr: `الجرعة ومعدل الاستخدام
الرش الورقي : 2 - 3 جرام / لتر ماء
التسميد الارضى - 2-3 كجم / فدان حسب نوع وعمر المحصول
فوائد المركب
علاج سريع الأعراض نقص عنصر الحديد الذي يدخل في انتاج الطاقة
يساعد النبات علي اتمام كل العمليات الحيوية يمتير الحديد عنصر هام وضروري لتكوين التربتوفان وهو الحمض الأميني الذي يساعد النبات على مقاومة الاجهاد الناتج عن الملوحة وتغيرات درجه حراره يلعب دور في قدره النبات على الاحتفاظ بالرطوبة وتكوين البروتين ، يدخل في تكوين هرمونات النمو ويشارك في تكوين وانتاج حبوب اللقاح وزيادة عدد الازهار والثمار تام الذوبان في الماء ويساعد على زيادة المحصول وتحسين جوده الثمار
هام العلاج الاصفرار الناتج عن نقص عنصر الحديد يدخل في تركيب
العديد من الانزيمات الازمة في بناء البروتين وايضا المديد من
الانزيمات اللازمة للأكسدة والاختزال المسؤولة عن عمليه التنفس`
        },
        {
                id: 28,
                imgURL: n10,
                descEn: `Chelated powder fertilizer is characterized by its ability to stimulate and accelerate flowering processes and stabilize flowers
. It helps to harmonize the rate of flowering and flowering in the plant.
It helps cell division and bud opening in trees and plants and improves growth.
. It helps to create a balance between the proportion of male and female flowers in the plant.
. It increases the rate of fruit formation, improves their properties and increases their size.
. It helps to accelerate the coloring of fruits, early ripening, and improves shape.
. Increases the shelf life of fruits after collection`,
                descAr: ` .سماد بودر مخلبي يتميز بقدرته على دفع وتسريع عمليات التزهير وتثبيت الأزهار
. يساعد على التجانس في معدل التزهير والعقد في النبات .
يساعد على انقسام الخلايا وتفتح البراعم في الأشجار والنباتات ويحسن النمو .
. يساعد على احداث توازن بين نسبة الأزهار المذكرة والمؤنثة في النبات .
. يزيد من معدل تكوين الثمار ويحسن خواصها ويزيد حجمها .
. يساعد على سرعة تلوين الثمار والتبكير في النضج وتحسين الشكل .
. يزيد من فترة صلاحية الثمار بعد الجمع`
        },
        {
                id: 29,
                imgURL: n11,
                descEn: `. Treating potassium deficiency, increasing organic matter and increasing root hairs
. Fulvic acid is a compound that is highly soluble in water and is retained in the soil for a long time
.
A soil improver used for various types of crops, with high experience in stimulating seed germination due to it containing the acids necessary for the germination process.
. It is used to increase root activation and soil adjustment. It increases the efficiency of using mineral fertilizers
. Increases the level of vitamins and minerals in the plant
. Reduces the effect of salinity in soil and irrigation water
. It activates the roots and root hairs and improves soil properties`,
                descAr: `. علاج نقص البوتاسيوم وزيادة المادة العضوية وزيادة الشعيرات الجذرية
. مركب فولفيك أسيد عالي الذوبان في الماء وتحتفظ به التربة فترة طويلة
.
محسن للتربة ويستخدم لمختلف أنواع الزراعات ذو خبرة عالية في تحفيز إنبات البذور نظراً لاحتوائه على الأحماض اللازمة لعملية الإنبات
. يستخدم لزيادة تنشيط الجذور وضبط التربة يزيد من كفاءة استخدام الأسمدة المعدنية
. يزيد مستوي الفيتامينات والمعادن في النبات
. يقلل من تأثير الملوحة في التربة ومياة الري
. يعمل على تنشيط الجذور والشعيرات الجذرية تحسين خواص التربة`
        },
        {
                id: 30,
                imgURL: n12,
                descEn: `"Solovaza".
<br/>Soil and water salinity treatment and root activation.
<br/>◻️It contains organic and mineral compounds that are effective and quick to resist any damage to the soil.
<br/>◻️Chemical composition:-
<br/>Calcium oxide 22%
<br/> Carboxylic acids 36%
<br/>Potassium 11%
<br/>Nitrogen 9%
<br/>Serbitol 2%
<br/>Fulvic 5%
<br/>Humic 5%
<br/>Indole 2%
<br/>◻️Characteristics and features:-
<br/>🔹It works to restore salts such as calcium from the soft form to the soft form.
<br/>🔹Helps get rid of sodium salts.
<br/>🔹It treats root rot and stimulates root growth.
<br/>🔹 Improves root absorption of elements and nutrients.
<br/>🔹It improves the general condition of the plant and works to adjust the pH of the soil around the roots.
<br/>🔹It has an effective role in expelling nematodes from the surroundings of the roots.
<br/>🔹Treats calcium deficiency and improves fruit specifications.
<br/>🔹 Gradually rids the earth of harmful sodium chloride and calcium carbonate salts.`,
                descAr: `" سولوفازا".
<br/>معالج ملوحة للتربة والمياه وتنشيط الجذور.
<br/>◻️من المركبات العضوية والمعدنية فعال وسريع في مقاومة أي ضرر يحدث للتربة.
<br/>◻️التركيب الكيميائي:-
<br/>اكسيد كالسيوم 22%
<br/>  احماض كربوكسيليه 36% 
<br/>بوتاسيوم 11% 
<br/>نيتروجين 9%
<br/>سربيتول 2%
<br/>فولفيك 5%
<br/>هيوميك 5%
<br/>اندول 2%
<br/>◻️الخصائص والمميزات:- 
<br/>🔹يعمل علي إعادة الأملاح مثل الكالسيوم من الصورة الغير ميسرة الصورة الميسرة.
<br/>🔹يساعد علي التخلص من أملاح الصوديوم.
<br/>🔹يعمل علي معالجة أعفان الجذور وتنشيط نمو الجذر.
<br/>🔹يحسن من امتصاص الجذور للعناصر والمغذيات.
<br/>🔹يحسن الحالة العامة للنبات ويعمل علي ضبط درجة ph التربة حول الجذور.
<br/>🔹ليه دور فعال في طرد النيماتودا من محيط الجذور.
<br/>🔹يعالج نقص الكالسيوم وتحسين مواصفات الثمار.
<br/>🔹يخلص الأرض تدريجيا من أملاح كلوريد الصوديوم وكربونات الكالسيوم الضارة.`
        },
        {
                id: 31,
                imgURL: n13,
                descEn: `▫️ Does the size make a difference for you, and does the shape have to be natural?
<br/>▫️ Of course, the quality of your crop is your goal and our goal, which is why we provide you with Fazza jackets.
<br/>▫️ It contains 40% potassium citrate.
<br/>▫️ It is beneficial for all stages of the plant, is quickly absorbed, and plays a very important role in reducing temperatures and many other benefits and features.
<br/>▫️Don’t succumb to heat waves and loss of your crops because we will protect you.
<br/>▫️ Quality and safety for a crop that satisfies you.`,
                descAr: `▫️الحجم بيفرق معاك وكمان لازم الشكل يكون طبيعى ؟
<br/>▫️ اكيد جودة محصولك هدفك وهدفنا علشان كدا بنوفر لك سترات فازا.
<br/>▫️فيه سترات بوتاسيوم بنسبة 40%.
<br/>▫️مفيد لكل مراحل النبات وسريع الإمتصاص ويبلعب دور مهم جدا فى تقليل درجات الحرارة وغيره كثير من الفوائد و المميزات.
<br/>▫️بلاش تستسلم لموجات الحر وخسارة محصولك لأننا هنحميك.
<br/>▫️جودة وأمان لمحصول يرضيك.`
        },
        {
                id: 32,
                imgURL: n14,
                descEn: `✨Fazza Celica
 Potassium silicate reduces heat stress and strengthens root hairs against rot and fungi.`,
                descAr: `✨فازا سيليكا 
 سيليكات البوتاسيوم رافع للاجهاد الحراري ومقوى للشعيرات الجذرية ضد الاعفان والفطريات`
        },
        {
                id: 33,
                imgURL: n15,
                descEn: `✨Matrix is ​​a unique group of growth and vegetative growth regulators that also help increase the quality and size of fruits.`,
                descAr: `✨ماتريكس مجموعة فريدة من منظمات النمو والمجموع الخضرى والتى تساعد أيضا على زيادة جودة وتحجيم الثمار`
        },
        {
                id: 34,
                imgURL: n16,
                descEn: `✨Fazza Roots”
 The liquid root stimulant contains high-quality elements to stimulate the root system and increase the plant’s resistance to soil and water problems.`,
                descAr: `✨فازا روتس"
 منشط الجذور السائل يحتوى على عناصر عالية الجودة لتنشيط المجموع الجذري وزيادة مقاومة النبات لمشاكل التربه والمياه.`
        },
        {
                id: 35,
                imgURL: n17,
                descEn: `✨Fazza Magic:
 Magical in stimulating growth and flowering and stabilizing fruit set and quality.`,
                descAr: `✨فازا ماجيك :
 الساحر فى تنشيط النمو والتزهير وتثبيت عقد الثمار وجودتها.`
        },
        {
                id: 36,
                imgURL: n18,
                descEn: `Fasa Ronse✨✨
<br/>✨ It contains phosphorus, which refreshes the plant, helps the growth of root hairs, and improves the general health of the plant.
<br/>It contains potassium in the form of sulfate, which helps the plant benefit from the rest of the elements present in the soil.
<br/>. It contains 5% indole, which helps in the spread of roots, their hardness, and resistance to harmful bacteria and fungi
<br/>. It contains fulvic and humic elements, which are the most important essential elements for plants
<br/>. It contains natural stimulants that help strengthen the roots and their spread and contribute to improving the fruits and their quality, as well as the stems and the vegetative system.`,
                descAr: `فازا رونس✨✨
<br/>✨ يحتوى على الفوسفور الذى ينعش النبات ويساعد على نمو الشعيرات الجذرية ويحسن الصحة العامة للنبات
<br/>.يحتوى على البوتاسيوم فى صورة سلفات التى تساعد على مساعدة النبات في الاستفادة من باقى العناصر الموجودة في التربه
<br/>. يحتوى على اندول 5%والذى يساعد على انتشار الجذور وصلابتها ومقاومة البكتيريا الضارة والفطريات
<br/>. يحتوى على عنصرى الفولفيك والهيوميك من أهم العناصر الأساسية للنباتات
<br/>. يحتوى على منشطات طبيعية تساعد على صلابة الجذور وانتشارها وتسهم في تحسين الثمار وجودتها وكذلك السيقان والمجموع الخضرى`
        },
        
        {
                id: 38,
                imgURL: n20,
                descEn: `▫️ Always know that there is no opportunity to experiment with the quality of your production.
<br/>▫️ Because your crops and crops are your labor, which at the time of harvesting is supposed to compensate for the effort and fatigue, this will not be achieved without Mafaaza products.
<br/>▫️ Because we will follow up with you everything you encounter and all your crop problems and how you can deal with them.
<br/>▫️ Because we are an integrated team of engineers and our experience in the field is for two years.
<br/>▫️ The quality of Mafaza products is unexpected and experience is the best proof.`,
                descAr: `▫️خليك دايما عارف إن مافيش فرصة للتجربه فى جودة إنتاجك.
<br/>▫️ ولأن محصولك و زرعتك تعبك اللى وقت حصاده مفروض تعوض المجهود والتعب مش هتحقق دا من غير منتجات Mafaaza .
<br/>▫️ لأننا هنتابع معاك كل حاجه تقابلك و كل مشاكل محصولك وإزاى تقدر تتعامل معاه.
<br/>▫️ لأننا فريق متكامل من المهندسين و خبرتنا فى المجال من سنتين.
<br/>▫️ جودة منتجات مفازا غير متوقعة والتجربة خير دليل.`
        },
        {
                id: 39,
                imgURL: n21,
                descEn: `▫️ Always know that there is no opportunity to experiment with the quality of your production.
<br/>▫️ Because your crops and crops are your labor, which at the time of harvesting is supposed to compensate for the effort and fatigue, this will not be achieved without Mafaaza products.
<br/>▫️ Because we will follow up with you everything you encounter and all your crop problems and how you can deal with them.
<br/>▫️ Because we are an integrated team of engineers and our experience in the field is for two years.
<br/>▫️ The quality of Mafaza products is unexpected and experience is the best proof.`,
                descAr: `▫️خليك دايما عارف إن مافيش فرصة للتجربه فى جودة إنتاجك.
<br/>▫️ ولأن محصولك و زرعتك تعبك اللى وقت حصاده مفروض تعوض المجهود والتعب مش هتحقق دا من غير منتجات Mafaaza .
<br/>▫️ لأننا هنتابع معاك كل حاجه تقابلك و كل مشاكل محصولك وإزاى تقدر تتعامل معاه.
<br/>▫️ لأننا فريق متكامل من المهندسين و خبرتنا فى المجال من سنتين.
<br/>▫️ جودة منتجات مفازا غير متوقعة والتجربة خير دليل.`
        },
        {
                id: 40,
                imgURL: n22,
                descEn: `▫️ Always know that there is no opportunity to experiment with the quality of your production.
<br/>▫️ Because your crops and crops are your labor, which at the time of harvesting is supposed to compensate for the effort and fatigue, this will not be achieved without Mafaaza products.
<br/>▫️ Because we will follow up with you everything you encounter and all your crop problems and how you can deal with them.
<br/>▫️ Because we are an integrated team of engineers and our experience in the field is for two years.
<br/>▫️ The quality of Mafaza products is unexpected and experience is the best proof.`,
                descAr: `▫️خليك دايما عارف إن مافيش فرصة للتجربه فى جودة إنتاجك.
<br/>▫️ ولأن محصولك و زرعتك تعبك اللى وقت حصاده مفروض تعوض المجهود والتعب مش هتحقق دا من غير منتجات Mafaaza .
<br/>▫️ لأننا هنتابع معاك كل حاجه تقابلك و كل مشاكل محصولك وإزاى تقدر تتعامل معاه.
<br/>▫️ لأننا فريق متكامل من المهندسين و خبرتنا فى المجال من سنتين.
<br/>▫️ جودة منتجات مفازا غير متوقعة والتجربة خير دليل.`
        },
        {
                id: 41,
                imgURL: n23,
                descEn: `▫️ Always know that there is no opportunity to experiment with the quality of your production.
<br/>▫️ Because your crops and crops are your labor, which at the time of harvesting is supposed to compensate for the effort and fatigue, this will not be achieved without Mafaaza products.
<br/>▫️ Because we will follow up with you everything you encounter and all your crop problems and how you can deal with them.
<br/>▫️ Because we are an integrated team of engineers and our experience in the field is for two years.
<br/>▫️ The quality of Mafaza products is unexpected and experience is the best proof.`,
                descAr: `▫️خليك دايما عارف إن مافيش فرصة للتجربه فى جودة إنتاجك.
<br/>▫️ ولأن محصولك و زرعتك تعبك اللى وقت حصاده مفروض تعوض المجهود والتعب مش هتحقق دا من غير منتجات Mafaaza .
<br/>▫️ لأننا هنتابع معاك كل حاجه تقابلك و كل مشاكل محصولك وإزاى تقدر تتعامل معاه.
<br/>▫️ لأننا فريق متكامل من المهندسين و خبرتنا فى المجال من سنتين.
<br/>▫️ جودة منتجات مفازا غير متوقعة والتجربة خير دليل.`
        },
        {
                id: 42,
                imgURL: n24,
                descEn: `▫️ Always know that there is no opportunity to experiment with the quality of your production.
<br/>▫️ Because your crops and crops are your labor, which at the time of harvesting is supposed to compensate for the effort and fatigue, this will not be achieved without Mafaaza products.
<br/>▫️ Because we will follow up with you everything you encounter and all your crop problems and how you can deal with them.
<br/>▫️ Because we are an integrated team of engineers and our experience in the field is for two years.
<br/>▫️ The quality of Mafaza products is unexpected and experience is the best proof.`,
                descAr: `▫️خليك دايما عارف إن مافيش فرصة للتجربه فى جودة إنتاجك.
<br/>▫️ ولأن محصولك و زرعتك تعبك اللى وقت حصاده مفروض تعوض المجهود والتعب مش هتحقق دا من غير منتجات Mafaaza .
<br/>▫️ لأننا هنتابع معاك كل حاجه تقابلك و كل مشاكل محصولك وإزاى تقدر تتعامل معاه.
<br/>▫️ لأننا فريق متكامل من المهندسين و خبرتنا فى المجال من سنتين.
<br/>▫️ جودة منتجات مفازا غير متوقعة والتجربة خير دليل.`
        },
        {
                id: 43,
                imgURL: n25,
                descEn: `▫️ Always know that there is no opportunity to experiment with the quality of your production.
<br/>▫️ Because your crops and crops are your labor, which at the time of harvesting is supposed to compensate for the effort and fatigue, this will not be achieved without Mafaaza products.
<br/>▫️ Because we will follow up with you everything you encounter and all your crop problems and how you can deal with them.
<br/>▫️ Because we are an integrated team of engineers and our experience in the field is for two years.
<br/>▫️ The quality of Mafaza products is unexpected and experience is the best proof.`,
                descAr: `▫️خليك دايما عارف إن مافيش فرصة للتجربه فى جودة إنتاجك.
<br/>▫️ ولأن محصولك و زرعتك تعبك اللى وقت حصاده مفروض تعوض المجهود والتعب مش هتحقق دا من غير منتجات Mafaaza .
<br/>▫️ لأننا هنتابع معاك كل حاجه تقابلك و كل مشاكل محصولك وإزاى تقدر تتعامل معاه.
<br/>▫️ لأننا فريق متكامل من المهندسين و خبرتنا فى المجال من سنتين.
<br/>▫️ جودة منتجات مفازا غير متوقعة والتجربة خير دليل.`
        },
        {
                id: 44,
                imgURL: n26,
                descEn: `▫️ Always know that there is no opportunity to experiment with the quality of your production.
<br/>▫️ Because your crops and crops are your labor, which at the time of harvesting is supposed to compensate for the effort and fatigue, this will not be achieved without Mafaaza products.
<br/>▫️ Because we will follow up with you everything you encounter and all your crop problems and how you can deal with them.
<br/>▫️ Because we are an integrated team of engineers and our experience in the field is for two years.
<br/>▫️ The quality of Mafaza products is unexpected and experience is the best proof.`,
                descAr: `▫️خليك دايما عارف إن مافيش فرصة للتجربه فى جودة إنتاجك.
<br/>▫️ ولأن محصولك و زرعتك تعبك اللى وقت حصاده مفروض تعوض المجهود والتعب مش هتحقق دا من غير منتجات Mafaaza .
<br/>▫️ لأننا هنتابع معاك كل حاجه تقابلك و كل مشاكل محصولك وإزاى تقدر تتعامل معاه.
<br/>▫️ لأننا فريق متكامل من المهندسين و خبرتنا فى المجال من سنتين.
<br/>▫️ جودة منتجات مفازا غير متوقعة والتجربة خير دليل.`
        },
];
