import { Box, CardMedia, Container, Typography } from '@mui/material'
import React from 'react'

function GlobalCard({ desc, img }) {
    return (
        <Container>
            <Box sx={{ display: { md: "flex", xs: "block" } }} alignItems={"center"} justifyContent={"space-between"} >
                <Typography
                    className={'animated fadeInLeftBig delay-3s'}
                    variant="subtitle1"
                    color={"GrayText"}
                    sx={{
                        maxWidth: { md: "50%", xs: "100%" },
                        fontSize: 20,
                        lineHeight: 1.2,
                        // marginBottom: "50px"
                    }}
                >{desc}
                </Typography>
                <CardMedia
                    className={'animated fadeInRightBig delay-3s'}
                    sx={{ width: { md: "27%", xs: "100%" }, borderRadius: '30px' }}
                    component="img"
                    alt="green iguana"
                    image={img}
                />
            </Box>
        </Container>
    )
}

export default GlobalCard