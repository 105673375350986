import { useState } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { CardMedia, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NavDrawer from "./NavDrawer";
// import TransLang from "./TransLang"
import { useTranslation } from "react-i18next";
// import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Logo from './../../assets/Logo/LogoM.png'
function NavBar() {
    const { t } = useTranslation()
    const [drawer, setDrawer] = useState(false);
    return (
        <>
            <AppBar position="static" elevation={0}
                style={{
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    // backgroundColor: "rgb(1 86 43 / 70%)",
                    padding: "10px"
                }} >
                <Container>
                    <Toolbar disableGutters>
                        <Link
                            to="/"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                marginLeft: "10%",
                            }}
                        >
                            <CardMedia
                                sx={{
                                    height: '75px',
                                    // borderBottomLeftRadius: "20px",
                                    width: "170px",
                                    // borderBottomRightRadius: "20px",
                                    objectFit: "cover",
                                }}
                                component="img"
                                alt="green iguana"
                                image={Logo}
                            />
                            {/* <img src={Logo} alt="logo" /> */}
                        </Link>
                        {/* <Typography sx={{ display: { md: "none", xs: "none" }, alignItems: "center" }} >

                            <Link
                                to="tel:920007123"
                                style={{
                                    color: "inherit",
                                    textDecoration: "none",
                                    fontSize: 15,
                                    fontWeight: "bold",

                                }}
                            >
                                <Box style={{ display: "flex", alignItems: "center" }} >
                                    <Typography>{t('ContactDirect')}
                                    </Typography>
                                    <LocalPhoneIcon /></Box>
                            </Link>
                        </Typography> */}
                        <Box
                            sx={{
                                flexGrow: 1,
                                marginInlineStart: 8,
                                gap: 5,
                                display: { md: "flex", xs: "none" },
                                alignItems: "center"
                            }}
                        >
                            <Link
                                to="/"
                                style={{
                                    color: "inherit",
                                    textDecoration: "none",
                                    fontSize: 18,
                                    fontWeight: "bold",
                                }}
                            >
                                {t('linksPages.home')}
                            </Link>
                            <Link
                                to="/about"
                                style={{
                                    color: "inherit",
                                    textDecoration: "none",
                                    fontSize: 18,
                                    fontWeight: "bold",
                                }}
                            >
                                {t('linksPages.aboutUs')}
                            </Link>
                            <Link
                                to="/contact"
                                style={{
                                    color: "inherit",
                                    textDecoration: "none",
                                    fontSize: 18,
                                    fontWeight: "bold",
                                }}
                            >
                                {t('linksPages.contact')}
                            </Link>
                            <Link
                                to="/service"
                                style={{
                                    color: "inherit",
                                    textDecoration: "none",
                                    fontSize: 18,
                                    fontWeight: "bold",
                                }}
                            >
                                {t('linksPages.PhotoGallery')}
                            </Link>
                            {/* <TransLang /> */}
                        </Box>
                        <Box
                            sx={{
                                flexGrow: 1,

                                display: { md: "none", xs: "flex" },
                                justifyContent: "end",
                            }}
                        >
                            <IconButton onClick={() => setDrawer(true)}>
                                <MenuIcon />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <NavDrawer drawer={drawer} setDrawer={setDrawer} />
        </>
    );
}
export default NavBar;
