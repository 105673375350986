import { CardMedia } from "@mui/material";
import React from "react";
import Carousel from "react-material-ui-carousel";
import cov1 from "../assets/images/cover1.png"
// import cov2 from "../assets/images/cover2.png"
function Header() {
    return (
        <>
            <Carousel
                sx={{
                    height: { md: 500, xs: 300 },
                    width: "90%",
                    margin: "auto",
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px"


                }}
            >
                <CardMedia
                    sx={{
                        height: { md: 500, xs: 300 },
                        borderBottomLeftRadius: "20px",
                        width: "100%",
                        borderBottomRightRadius: "20px",
                        objectFit: "fill",
                    }}
                    component="img"
                    alt="green iguana"
                    image={cov1}
                />
                {/* <CardMedia
                    sx={{
                        height: { md: 500, xs: 300 },
                        borderBottomLeftRadius: "20px",
                        width: "100%",
                        borderBottomRightRadius: "20px",
                        objectFit: "fill",
                    }}
                    // sx={{ hight: { md: 500, xs: 300 } }}
                    component="img"
                    alt="green iguana"
                    image={cov2}
                /> */}
            </Carousel>
        </>
    );
}

export default Header;
