import { Box, Container, Typography } from "@mui/material";
import React from "react";

function Section({ children, title }) {
    return (
        <Container maxWidth="lg">
            <Box
                sx={{
                    marginTop: 8,
                    marginBottom: 2,
                }}
            >
                <Typography
                    variant="h4"
                    component="h3"
                    sx={{
                        mb: "10px",
                        fontWeight: "bold",
                        color: "#01562b"
                    }}
                >
                    {title}
                </Typography>
                {children}
            </Box>
        </Container>
    );
}

export default Section;
