import { CardMedia, Stack } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import "./SotialIcon.css"
import facebook from './../assets/images/Facebook.png'
import instagram from './../assets/images/Instagram.svg'
function SotialIcon() {
    return (
        <Stack direction="row" gap={3} justifyContent={"center"} margin={"0 1%"}>
            <Link
                to="https://www.facebook.com/profile.php?id=100095358533378"
                style={{
                    color: "inherit",
                    textDecoration: "none",
                    fontSize: 15,
                    fontWeight: "bold",
                }}
            >
                <CardMedia
                component="img"
                height="40px"
                image={facebook}
                alt="green iguana"
            />
            </Link>
            <Link
                to="https://www.instagram.com/mafazacompany9?igsh=MWk2ZW90YjBteDJw"
                style={{
                    color: "inherit",
                    textDecoration: "none",
                    fontSize: 15,
                    fontWeight: "bold",
                }}
            >
                <CardMedia
                component="img"
                height="40px"
                image={instagram}
                alt="green iguana"
            />
            </Link>
            
        </Stack>
    )
}

export default SotialIcon