import { Box, CardMedia, Container } from '@mui/material'
import React from 'react'
// import { useTranslation } from 'react-i18next'
import Accordions from './Accordions'

function ServisCard({ img }) {
    // const { t } = useTranslation()
    return (
        <Container
            sx={{
                // mt: "-70px",
                // mb: 5,
            }}
        >
            <Box display={"flex"} flexDirection={{ md: "row", xs: "column" }} gap={4} sx={{ margenTop: { md: 0, xs: "50px" } }} alignItems={"center"} justifyContent={"space-between"} >
                <Accordions />
                <CardMedia
                    sx={{ width: { md: "35%", xs: "100%" }, borderRadius: '30px' }}
                    component="img"
                    alt="green iguana"
                    image={img}
                />
            </Box>
        </Container>
    )
}

export default ServisCard