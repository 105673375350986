import { Box } from "@mui/material";
import React from "react";
// import Header1 from "../components/Header1";

import GlobalCard from "../components/GlobalCard";
import mainLogo from "../assets/pp.png"
import MainSubscripe from "../components/MainSubscripe";
import Section from "../components/Ui/Section";
// import Header from "../components/Header";

import { useTranslation } from "react-i18next";
import TestimonialsList from "../components/TestimonialsList";

function About() {
    const { t } = useTranslation()
    return (
        <Box>
            {/* <Header /> */}
            {/* <Header1 title={t("aboutUs.title")} /> */}
            <Section title={t("aboutUs.title")}>
                <GlobalCard desc={t("aboutUs.descAbout")}
                    img={mainLogo} />
            </Section>
            <Section>
                <TestimonialsList />
            </Section>
            <Section>
                <MainSubscripe />
            </Section>
        </Box>
    );
}

export default About;
