import React, { useRef } from "react";
import {
    Box,
    Button,
    TextField,
    Typography,
} from "@mui/material";
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
// import axios from "axios";
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
function TravlForm() {
    const { t } = useTranslation()
    const form = useRef()
    const { register, handleSubmit, reset } = useForm();
    const sendEmail = (e) => {
        // e.preventDefault();
        emailjs.sendForm('service_v3xx3fc', 'template_cu46jy4', form.current, 'yTwXeb8rPgaLWe7Cl')
            .then((result) => {
                console.log(result.text);
                enqueueSnackbar("Data sent successfully👍")
                reset()
            }, (error) => {
                console.log(error.text);
                enqueueSnackbar(error, { variant: 'error' });
                reset()
            });
        reset()
    }
    return (
        <Box mt={2}>
            <Typography
                variant="h5"
                component="h3"
                sx={{
                    mb: 5,
                    fontWeight: "bold",
                    maxWidth: 600,
                }}
            >
                {t("contactMe.contactUsDesc")}
            </Typography>

            <Box component="form" gap={20} ref={form} onSubmit={handleSubmit(sendEmail)} mt={1} name="from_name" >
                <Box display={"flex"} gap={2} alignItems={"center"}>
                    <PermIdentityOutlinedIcon />
                    <Typography
                        variant="h6"
                        component="h3"
                        sx={{
                            mb: 1,
                            maxWidth: 600,
                        }}
                    >
                        {t("contactMe.FullName")}
                    </Typography>
                </Box>
                <TextField
                    placeholder={t("contactMe.FullName")}
                    fullWidth
                    name="name"
                    {...register("name", { required: true })}
                    // onChange={(e) => setPhone(e.target.value)}
                    required
                    sx={{
                        mb: 1,
                        backgroundColor: "#FFF",
                    }}
                />
                <Box display={"flex"} gap={2} alignItems={"center"}>
                    <PermIdentityOutlinedIcon />
                    <Typography
                        variant="h6"
                        component="h3"
                        sx={{
                            mb: 1,
                            maxWidth: 600,
                        }}
                    >
                        "البريد الألكتروني"
                    </Typography>
                </Box>
                <TextField
                    placeholder="البريد الألكتروني"
                    fullWidth
                    name="email"
                    {...register("email", { required: true })}
                    // onChange={(e) => setPhone(e.target.value)}
                    required
                    sx={{
                        mb: 1,
                        backgroundColor: "#FFF",
                    }}
                />
                <Box display={"flex"} gap={2} alignItems={"center"}>
                    <LocalPhoneIcon />
                    <Typography
                        variant="h6"
                        component="h3"
                        sx={{
                            mb: 1,
                            maxWidth: 600,
                        }}
                    >
                        {t("contactMe.phone")}
                    </Typography>
                </Box>
                <TextField
                    fullWidth
                    placeholder={t("contactMe.phone")}
                    required
                    name="phone"
                    {...register("phone", { required: true })}
                    // onChange={(e) => setCountry(e.target.value)}
                    sx={{
                        mb: 1,
                        backgroundColor: "#FFF",
                    }}
                >
                </TextField>

                <Box display={"flex"} gap={2} alignItems={"center"}>
                    <ForumOutlinedIcon />
                    <Typography
                        variant="h6"
                        component="h3"
                        sx={{
                            mb: 1,
                            maxWidth: 600,
                        }}
                    >
                        {t("contactMe.message")}
                    </Typography>
                </Box>
                <TextField
                    fullWidth
                    placeholder={t("contactMe.message")}
                    name="message"
                    required
                    {...register("message", { required: true })}
                    // onChange={(e) => setCity(e.target.value)}
                    sx={{
                        mb: 1,
                        backgroundColor: "#FFF",
                    }}
                />

                <br />
                <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    // color="#01562b"
                    size="large"
                    sx={{
                        mt: 1,
                        mb: 1,
                        color: "white",
                        fontSize: 18,
                        background: '#01562b'
                    }}
                >
                    {t("contactMe.sendMessage")}
                </Button>
            </Box>
        </Box>
    );
}

export default TravlForm;
