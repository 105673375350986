import { Card, CardMedia } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

function TourCardserv({ imgs, url }) {
  return (
    <Link to={url} target='_blank' >
      <Card
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          overflow: "inherit",
          margin: "15px",
          borderRadius: '30px'
        }}
      >
        <CardMedia
          sx={{
            "&.MuiCardMedia-root:hover": {
              transform: "scale(1.1)",
              transition: ".5s"
            },
            borderRadius: '30px'
          }}
          component="img"
          height="350px"
          image={imgs}
          alt="green iguana"
        />
      </Card>
    </Link>
  )
}

export default TourCardserv