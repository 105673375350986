import React from 'react'
// import Review from './Review'
// import WhyUs from "../assets/images/Ramadan/CE 1.png"
import Carousel from 'react-material-ui-carousel'

function ListReview() {
    return (
        <>
            <Carousel
                height={400}
                sx={{
                    marginTop: "25px",
                    width: { md: "65%", xs: "100%" },
                    margin: "auto",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
                    borderRadius: '30px'
                }}>

                <img
                    fetchpriority="high"
                    src="images/rev/rev1.jpg"
                    alt=""
                    style={{
                        height: 400,
                        borderRadius: "20px",
                        width: "100%",
                        objectFit: "fill",
                    }}
                />
                <img
                    fetchpriority="high"
                    src="images/rev/rev2.jpg"
                    alt=""
                    style={{
                        height: 400,
                        borderRadius: "20px",
                        width: "100%",
                        objectFit: "fill",
                    }}
                />
                <img
                    fetchpriority="high"
                    src="images/rev/rev3.jpg"
                    alt=""
                    style={{
                        height: 400,
                        borderRadius: "20px",
                        width: "100%",
                        objectFit: "fill",
                    }}
                />
            </Carousel>
        </>
    )
}

export default ListReview