import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import "./Testimonials.css"
import React from "react";
// import { useTranslation } from "react-i18next";

function Testimonials({ title, desc, Icon }) {
    // const { t } = useTranslation()
    return (
        <>
            <Stack
                direction="row"
                gap={3}
                flexDirection={"column"}
                alignItems={"center"}
                className="cardTestim"
                p={2}
            >
                <Stack>
                    {Icon}

                    <Typography gutterBottom variant="h5" component="h5" sx={{
                        mb: 3,
                        fontWeight: "bold",
                        color: "#01562b"
                    }}
                    >
                        {title}
                    </Typography>
                    <Typography gutterBottom variant="h6" component="h5">
                        {desc}
                    </Typography>
                    {/* <Button
                        variant="contained"
                        sx={{
                            backgroundColor: "#94c120",
                            fontWeight: "bold",
                            width: "50%",
                            margin: "20px 20px 0 0"
                        }}>{t("more")}</Button> */}
                </Stack>
            </Stack>
        </>
    );
}

export default Testimonials;
