import React from 'react'
import Carousel from 'react-material-ui-carousel'
import M1 from './../assets/Service/M8.jpg'
import M2 from './../assets/Service/M11.jpg'
import M3 from './../assets/Service/M10.jpg'
import M4 from './../assets/Service/M14.jpg'
import M5 from './../assets/Service/M15.jpg'
// import M6 from './../assets/Service/M12.jpg'
import M7 from './../assets/Service/M2.jpg'
// import M8 from './../assets/Service/مفازا_page-0016.jpg'
function SumServ() {
    return (
        <>

            <Carousel
                className={'animated fadeInDown delay-3s'}
                height={400}
                sx={{
                    marginTop: "25px",
                    width: { md: "50%", xs: "100%" },
                    margin: "auto"
                }}>
                <a href={M1} target='_blank' rel="noreferrer" >
                    <img
                        fetchpriority="high"
                        src={M1}
                        alt=""
                        style={{
                            height: 400,
                            borderRadius: "20px",
                            width: "100%",
                            objectFit: "cover",
                        }}
                    />
                </a>
                <a href={M2} target='_blank' rel="noreferrer" >

                    <img
                        fetchpriority="high"
                        src={M2}
                        alt=""
                        style={{
                            height: 400,
                            borderRadius: "20px",
                            width: "100%",
                            objectFit: "cover",
                        }}
                    />
                </a>
                <a href={M3} target='_blank' rel="noreferrer" >

                    <img
                        fetchpriority="high"
                        src={M3}
                        alt=""
                        style={{
                            height: 400,
                            borderRadius: "20px",
                            width: "100%",
                            objectFit: "cover",
                        }}
                    />
                </a>
                <a href={M4} target='_blank' rel="noreferrer" >

                    <img
                        fetchpriority="high"
                        src={M4}
                        alt=""
                        style={{
                            height: 400,
                            borderRadius: "20px",
                            width: "100%",
                            objectFit: "cover",
                        }}
                    />
                </a>
                <a href={M5} target='_blank' rel="noreferrer" >

                    <img
                        fetchpriority="high"
                        src={M5}
                        alt=""
                        style={{
                            height: 400,
                            borderRadius: "20px",
                            width: "100%",
                            objectFit: "cover",
                        }}
                    />
                </a>
                {/* <a href={M6} target='_blank' rel="noreferrer" >

                    <img
                        fetchpriority="high"
                        src={M6}
                        alt=""
                        style={{
                            height: 400,
                            borderRadius: "20px",
                            width: "100%",
                            objectFit: "cover",
                        }}
                    />
                </a> */}
                <a href={M7} target='_blank' rel="noreferrer" >

                    <img
                        fetchpriority="high"
                        src={M7}
                        alt=""
                        style={{
                            height: 400,
                            borderRadius: "20px",
                            width: "100%",
                            objectFit: "cover",
                        }}
                    />
                </a>
                {/* <a href={M1} target='_blank' rel="noreferrer" >

                    <img
                        fetchpriority="high"
                        src={M8}
                        alt=""
                        style={{
                            height: 400,
                            borderRadius: "20px",
                            width: "100%",
                            objectFit: "cover",
                        }}
                    />
                </a> */}
                {/* <img
                    fetchpriority="high"
                    src={M9}
                    alt=""
                    style={{
                        height: 400,
                        borderRadius: "20px",
                        width: "100%",
                        objectFit: "cover",
                    }}
                /> */}
            </Carousel>
        </>
    )
}

export default SumServ