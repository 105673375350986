import React from "react";
import Header from "../components/Header";
import TestimonialsList from "../components/TestimonialsList";
import Section from "../components/Ui/Section";
import GlobalCard from "../components/GlobalCard";
import mainLogo from "../assets/pp.png"
import mainServ from "../assets/pp.png"
import WhyUs from "../assets/pp.png"
import ServisCard from "../components/ServisCard";
import ListReview from "../components/ListReview";
import MainSubscripe from "../components/MainSubscripe";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom"
import SumServ from "../components/SumServ";
import IconSo from "../components/IconSo";

function Home() {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <>
            <Header />
            <Section title={t("home.whyCamelexpress")}>
                <IconSo />
                <GlobalCard desc={t("home.whyCamelexpressDesc")}
                    img={WhyUs} />
                <Button variant="contained"
                    sx={{ backgroundColor: "#01562b", fontWeight: "bold", margin: "5px 20px 0 0", color: "white", width: '125px', fontSize: '1rem' }}
                    onClick={() => navigate("/about")} >{t('linksPages.aboutUs')}</Button>
            </Section>
            <Section title={t('home.sumServ')}>
                <SumServ />
            </Section>
            <Section title={t('home.byTheExecutiveDirector')}>
                <GlobalCard desc={t('home.byTheExecutiveDirectorDesc')}
                    img={mainLogo} />
            </Section>
            <Section >
                <TestimonialsList />
            </Section>
            <Section title={t('home.ourServicesAre')}>
                <ServisCard
                    img={mainServ} />
            </Section>
            <Section title={t("home.Testimonials")}>
                <ListReview />
            </Section>
            <Section>
                <MainSubscripe />
            </Section>
        </>
    );
}

export default Home;
