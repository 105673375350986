import { Box } from '@mui/material'
import React from 'react'
import Section from '../components/Ui/Section'
import MainSubscripe from '../components/MainSubscripe'
// import Header from '../components/Header'
import ToursListServ from '../components/ToursListServ'
import { useTranslation } from 'react-i18next'

function AlpomServ() {
  const { t } = useTranslation()
  return (
    <Box>
      {/* <Header /> */}
      <Section title={t('linksPages.PhotoGallery')}>
        <ToursListServ />
      </Section>
      <Section>
        <MainSubscripe />
      </Section>
    </Box>
  )
}

export default AlpomServ