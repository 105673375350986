import { Box, Button, CardMedia, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { products } from './../constants'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom'
const Service = () => {
    const navigate = useNavigate();
    const { id } = useParams()
    const [product, setProduct] = useState(null);


    useEffect(() => {
        // البحث عن المنتج بناءً على "id"
        const foundProduct = products.find((product) => product.id === Number(id));
        // تحديث الحالة "product"
        setProduct(foundProduct);
    }, [id]);
    return (
        <div>
            <>
                <Box>
                    <Box className='container-fluid' sx={{ width: { md: '95%', xs: '100%' }, margin: 'auto' }}>
                        <Box className="row">
                            {
                                <Box className="col-md-12" key={product?.id} >
                                    <Box className="card" style={{ width: { md: "100%", xs: '90%' }, margin: { md: '5px', xs: 0 }, padding: { md: '0 2%', xs: '0 5%' } }}>
                                        <CardMedia component={'img'} style={{ height: '100vh', objectFit: 'fill' }} src={product?.imgURL} alt="..." />
                                        <Box className="card-body" sx={{ padding: { md: '2%', xs: '15px' } }}>
                                            {/* <div className="like d-flex" sx={{ display: 'flex', flexDirection: 'row-reverse', gap: '25px' }}>
                                                <p className="fw-bold">{product?.subtextEn}</p>
                                                <p className="fw-bold">{product?.subtextAr}</p>
                                            </div> */}
                                            <Typography sx={{ display: 'flex', flexDirection: 'row-reverse', gap: '25px' }} >
                                                <div className="task-des" dangerouslySetInnerHTML={{
                                                    __html: product?.descEn
                                                }}></div>
                                                <div className="task-des" dangerouslySetInnerHTML={{
                                                    __html: product?.descAr
                                                }}></div>
                                            </Typography>
                                            <Box sx={{ textAlign: 'end', margin: '0 0 0 5%' }} >
                                                <Button variant="contained" style={{ background: 'linear-gradient(180deg, rgba(58,134,96,1) 35%, rgba(26, 103, 64, 1) 100%)', color: "#FFF", gap: 14 }}
                                                    endIcon={<ArrowBackIcon />} onClick={() => navigate(`/service`)}>
                                                    {`الرجوع لكل الاعمال`}
                                                </Button>
                                            </Box>
                                            <hr />
                                        </Box>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    </Box>
                </Box>
            </>
        </div>
    )
}

export default Service
