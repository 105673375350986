import { Box, Grid } from '@mui/material'
import React from 'react'
// import Header1 from '../components/Header1'
import Section from '../components/Ui/Section'
import MainSubscripe from '../components/MainSubscripe'
import TravlForm from '../components/TravlForm'
// import Header from '../components/Header'
import { useTranslation } from 'react-i18next'
import ContactData from '../components/ContactData/ContactData'
function Contact() {
  const { t } = useTranslation()
  return (
    <Box>
      {/* <Header /> */}
      {/* <Header1 title={t("contactMe.contactUs")} /> */}
      <Section title={t("contactMe.contactUs")} >
        <Box sx={{ p: { md: 3, xs: 0 } }} >
          <Grid container spacing={{ md: 4, xs: 0 }} sx={{ display: 'flex', alignItems: 'center' }} >
            <Grid item md={6} xs={12}>
              <TravlForm />
            </Grid>
            <Grid item md={6} xs={12}>
              <ContactData />
            </Grid>
          </Grid>
        </Box>
      </Section>
      <Section>
        <MainSubscripe />
      </Section>
    </Box>
  )
}

export default Contact