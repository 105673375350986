import React from 'react'
import { Box, Button, Container, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
function MainSubscripe() {
    const navigate = useNavigate()
    const { t } = useTranslation()
    return (
        <Container>
            <Box
                style={{
                    width: "100%",
                    background: "#1a6740",
                    // height: "125px",
                    borderRadius: "20px",
                    padding: "30px",
                    gap: 10
                }}
                display={'flex'}
                flexWrap={"wrap"}
                gap={{ md: 2, xs: 0 }}
                alignItems={'center'}
                justifyContent={'space-around'} >
                <Box><Typography
                    sx={{ color: 'white', }}
                    fontWeight={'bold'}>
                    {t("home.needService")}
                </Typography>
                    <Typography
                        sx={{ color: 'white', }}
                        fontWeight={'bold'}>
                        {t("home.needServiceDesc")}
                    </Typography></Box>
                <Button
                    onClick={() => navigate("/contact")}
                    style={{
                        fontWeight: "bold", backgroundColor: 'rgb(25 102 63)',
                        width: "125px",
                        border: '1px solid'
                    }}>{t("home.SubscribeNow")}</Button>
            </Box>
        </Container>
    )
}

export default MainSubscripe