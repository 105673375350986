import { Grid } from '@mui/material'
import React from 'react'
import TourCardserv from './TourCardserv'
import { products } from './../constants'

function ToursListServ() {

    return (
        <>
            <Grid container>
                {products.map((product) =>
                    <Grid item xs={12} md={4}
                        key={product?.id}>
                        <TourCardserv imgs={product.imgURL} url={`${product?.imgURL}`} />
                    </Grid>
                )}
            </Grid>
        </>
    )
}

export default ToursListServ