import { Grid } from "@mui/material";
import React from "react";
import Testimonials from "./Testimonials";
import { useTranslation } from "react-i18next";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import CommentIcon from '@mui/icons-material/Comment';
function TestimonialsList() {
    const { t } = useTranslation()
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
                <Testimonials Icon={<TipsAndUpdatesIcon style={{
                    margin: "auto",
                    height: " 50px",
                    width: " 50px",
                    color: "#01562b"
                }} />} title={t('home.ourVision')} desc={t("home.ourVisionDesc")} />
            </Grid>
            <Grid item xs={12} md={4}>
                <Testimonials Icon={<MiscellaneousServicesIcon style={{
                    margin: "auto",
                    height: " 50px",
                    width: " 50px",
                    color: "#01562b"
                }} />} title={t('home.ourServices')} desc={t("home.ourServicesDesc")} />
            </Grid>
            <Grid item xs={12} md={4}>
                <Testimonials Icon={<CommentIcon style={{
                    margin: "auto",
                    height: " 50px",
                    width: " 50px",
                    color: "#01562b"
                }} />} title={t('home.ourMessage')} desc={t("home.ourMessageDesc")} />
            </Grid>
        </Grid>
    );
}

export default TestimonialsList;
